import moment from 'moment';
import { CSVLink } from 'react-csv';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Button from '@material-ui/core/Button';
import React, { Component, Fragment } from 'react';
import { validate } from 'wallet-address-validator';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import {
  setLoader, getAllowListedSignups, addNewAllowlistSignups, toggleAddAllowistModal, updateAllowListedSignups, toggleEditAllowistModal,
  deleteAllowListedSignups, toggleDeleteAllowistModal
} from '../../store/actions/Auth'


class AllowlistSignups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      address: '',
      firstName: '',
      deleteRecord: { email: '', address: '', firstName: '' }
    };
  };

  async componentDidMount() {
    this.props.getAllowListedSignups();
  };

  componentWillReceiveProps({ isAddAllowlistModal, isEditAllowlistModal, isDeleteAllowlistModal }) {
    if (!isAddAllowlistModal && !isEditAllowlistModal) this.setState({ address: '', email: '', firstName: '' });
    if (!isDeleteAllowlistModal) {
      let { deleteRecord } = this.state;
      deleteRecord['email'] = '';
      deleteRecord['firstName'] = '';
      deleteRecord['address'] = '';
      this.setState({ deleteRecord });

    }
  };


  handleEditChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // if ([e.target.name] == 'address') {
    //   let { addressArray } = this.state;
    //   let address = e.target.value;
    //   addressArray = address.split(',');
    //   this.setState({ addressArray })
    // }
  };

  addNewAllowlist = async () => {
    try {
      let { address, email, firstName } = this.state;

      if (!(address || email)) {
        EventBus.publish('error', `Please provide at least one of Address or Email`);
        return;
      }

      if (address && !validate(address, 'ETH')) {
        EventBus.publish('error', `Please provide valid address`);
        return;
      };

      if (email && !this.validateEmail(email)) {
        EventBus.publish('error', `Please provide valid Email adderss`);
        return;
      };

      this.props.setLoader({ message: 'Adding New Allowlist Signup ...', status: true });
      this.props.addNewAllowlistSignups({ publicAddress: address, email, firstName });
      // export csv remaining 

    } catch (error) {
      this.props.toggleAddAllowistModal(false);
      EventBus.publish('error', `Unable to add new allowlist address`);
      this.props.setLoader({ message: 'Allowlisting not Completed...', status: false });
    }
  };

  validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  editAllowlist = async () => {
    try {
      let { address, email, firstName } = this.state;

      if (!(address || email)) {
        EventBus.publish('error', `Please provide at least one of Address or Email`);
        return;
      }

      if (address && !validate(address, 'ETH')) {
        EventBus.publish('error', `Please provide a valid Ethereum address`);
        return;
      }

      if (email && !this.validateEmail(email)) {
        EventBus.publish('error', `Please provide a valid email address`);
        return;
      }

      this.props.setLoader({ message: 'Updating Allowlist Record...', status: true });
      this.props.updateAllowListedSignups({ address, email, firstName });

    } catch (e) {
      this.props.toggleEditAllowistModal(false);
      EventBus.publish('error', `Unable to Allowlist Address`);
      this.props.setLoader({ message: 'Allowlisting not Completed...', status: false });
    };
  };

  editRow = async (row) => {
    // Extract the data from the row
    const { publicAddress, createdAt, email, firstName } = row._original;
    this.setState({ address: publicAddress, createdAt, email, firstName },
      () => this.props.toggleEditAllowistModal(true));
  };

  deleteRecord = async () => {
    try {
      let { address, email, firstName } = this.state.deleteRecord;
      if (email == '') {
        EventBus.publish('info', 'Email is not added');
        return;
      }
      if (address == '') {
        EventBus.publish('info', 'Address is not added');
        return;
      }
      if (firstName == '') {
        EventBus.publish('info', 'First name is not added');
        return;
      }
      this.props.setLoader({ message: 'Deleting Allowlist Record...', status: true });
      this.props.deleteAllowListedSignups({ address, email, firstName });

    } catch (error) {
      this.props.toggleDeleteAllowistModal(false);
      EventBus.publish('error', `Unable to delete this record`);
      this.props.setLoader({ message: 'Record not Deleted...', status: false });
    }
  };

  deleteRow = async (row) => {
    // Extract the data from the row
    let { deleteRecord } = this.state;
    const { publicAddress, email, firstName } = row._original;

    // Set the data in the state to populate the fields in the modal
    this.props.toggleDeleteAllowistModal(true);
    deleteRecord['email'] = email;
    deleteRecord['firstName'] = firstName;
    deleteRecord['address'] = publicAddress;
    this.setState({ deleteRecord });
  };


  render() {

    let { address, email, firstName, deleteRecord } = this.state;
    let { allAddressesSignups, isAddAllowlistModal, isEditAllowlistModal, isDeleteAllowlistModal } = this.props;

    const columns = [
      {
        id: 'returnValues',
        Header: 'Allowlisted Address',
        accessor: (allAddressesSignups) => allAddressesSignups['publicAddress'] ? allAddressesSignups['publicAddress'] : '-',
      },
      {
        id: 'blockNumber',
        Header: 'Date',
        accessor: (allAddressesSignups) => moment(allAddressesSignups['createdAt']).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        id: 'Email',
        Header: 'Email',
        accessor: (allAddressesSignups) => allAddressesSignups['email']
      },
      {
        id: 'First Name',
        Header: 'First Name',
        accessor: (allAddressesSignups) => allAddressesSignups['firstName']
      },
      {
        id: 'edit',
        Header: '',
        Cell: ({ row }) => (
          <>
            <Button onClick={() => this.editRow(row)} variant="outlined" color="primary">Edit</Button>
            <Button onClick={() => this.deleteRow(row)} variant="outlined" color="primary" className='delete-button'>Delete</Button>
          </>
        )
      },
    ];

    return (
      <div className='content'>
        <div className="main-container mintedNFT">
          <div className='main-container-head mb-3'>
            <p className="main-container-heading">Allowlist Signup's</p>
            <div className='action-buttons'>
              <button onClick={() => this.props.toggleAddAllowistModal(true)} className="add-btn">Add Allowlist Signup</button>
              <CSVLink data={allAddressesSignups} filename={"allowListed.csv"} >
                <button className="add-btn"
                >Export Data</button>
              </CSVLink>
            </div>
          </div>
          <Fragment>
            <div className='main-container-head mb-3'>
              <ReactTable
                minRows={20}
                className="table"
                columns={columns}
                filterable={true}
                data={allAddressesSignups}
                resolveData={allAddressesSignups => allAddressesSignups.map(row => row)}
              />
            </div>
          </Fragment>
        </div>


        {/* ---------------ADD ALLOWLIST MODAL--------------- */}

        <Modal isOpen={isAddAllowlistModal} toggle={() => this.props.toggleAddAllowistModal(false)} className="main-modal allowlist-modal" style={{ margin: "auto" }}>
          <ModalHeader toggle={() => this.props.toggleAddAllowistModal(false)}>
            <div className="modal-logo">
              <img src={require('../../assets/img/logo.png')} alt="modal-logo" />
            </div>
            <div className="text-center"><h2 className="text-white">NEW ALLOWLISTED SIGNUPS</h2></div>
            <div className="text-white"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.addNewAllowlist}>
              <div className='row'>
                <div className='col-12'>
                  <span style={{ color: 'white' }}>Address: </span>
                  <TextValidator
                    fullWidth
                    multiline
                    rows={1}
                    type='text'
                    name='address'
                    value={address}
                    placeholder="Enter the Addresses"
                    className='text-field'
                    id='standard-full-width'
                    onChange={this.handleEditChange}
                  />
                </div>


                <div className='col-12'>
                  <span style={{ color: 'white' }}>Email: </span>
                  <TextValidator
                    fullWidth
                    multiline
                    rows={1}
                    type='email'
                    name='email'
                    value={email}
                    placeholder="Enter Email"
                    className='text-field'
                    id='standard-full-width'
                    onChange={this.handleEditChange}
                  />
                </div>

                <div className='col-12'>
                  <span style={{ color: 'white' }}>First Name: </span>
                  <TextValidator
                    fullWidth
                    multiline
                    rows={1}
                    type='text'
                    name='firstName'
                    value={firstName}
                    placeholder="Enter First Name"
                    className='text-field'
                    id='standard-full-width'
                    onChange={this.handleEditChange}
                  />
                </div>



              </div>
              <div className="row">
                <div className="col-12 pt-4 pb-5 d-flex justify-content-center">
                  <Button type="submit" variant="contained" className="add-btn">
                    Submit
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>

        </Modal>

        {/* ---------------EDIT ALLOWLIST MODAL--------------- */}

        <Modal isOpen={isEditAllowlistModal} toggle={() => this.props.toggleEditAllowistModal(false)} className="main-modal allowlist-modal" style={{ margin: "auto" }}>
          <ModalHeader toggle={() => this.props.toggleEditAllowistModal(false)}>
            <div className="modal-logo">
              <img src={require('../../assets/img/logo.png')} alt="modal-logo" />
            </div>
            <div className="text-center"><h2 className="text-white">EDIT ALLOWLISTED</h2></div>
            <div className="text-white"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.editAllowlist}>
              <div className='row'>
                <div className='col-12'>
                  <span style={{ color: 'white' }}>Address: </span>
                  <TextValidator
                    fullWidth
                    multiline
                    rows={1}
                    type='text'
                    name='address'
                    value={address}
                    placeholder="Public Address"
                    className='text-field'
                    id='standard-full-width'
                    onChange={this.handleEditChange}
                  />
                </div>

                <div className='col-12'>
                  <span style={{ color: 'white' }}>Email: </span>
                  <TextValidator
                    fullWidth
                    multiline
                    rows={1}
                    type='text'
                    name='email'
                    value={email}
                    placeholder="Enter Email"
                    className='text-field'
                    id='standard-full-width'
                    onChange={this.handleEditChange}
                  />
                </div>

                <div className='col-12'>
                  <span style={{ color: 'white' }}>First Name: </span>
                  <TextValidator
                    fullWidth
                    multiline
                    rows={1}
                    type='text'
                    name='firstName'
                    value={firstName}
                    placeholder="Enter First Name"
                    className='text-field'
                    id='standard-full-width'
                    onChange={this.handleEditChange}
                  />
                </div>

              </div>
              <div className="row">
                <div className="col-12 pt-4 pb-5 d-flex justify-content-center">
                  <Button type="submit" variant="contained" className="add-btn">Submit</Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>

        </Modal>

        {/*------------------------DELETE MODAL ---------------------*/}

        <Modal isOpen={isDeleteAllowlistModal} toggle={() => this.props.toggleDeleteAllowistModal(false)} className="main-modal allowlist-modal" style={{ margin: "2.9rem auto" }}>
          <ModalHeader toggle={() => this.props.toggleDeleteAllowistModal(false)}>
            <div className="modal-logo">
              <img src={require('../../assets/img/logo.png')} alt="modal-logo" />
            </div>
            <div className="text-center"><h2 className="text-white">DELETE ALLOWLISTED</h2></div>

          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="text-center">
              <h4 className="text-white">Are you sure you want to delete?</h4>
              <h4 className="text-white">{deleteRecord['firstName']} ({deleteRecord['address'] || deleteRecord['email']}) </h4>
            </div>
            <div className="text-white"><hr /></div>
            <div className='d-flex justify-content-center mb-4'>
              <Button onClick={() => this.deleteRecord()} variant="contained" className="add-btn">Delete</Button>
            </div>

          </ModalBody>
        </Modal>


      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader, getAllowListedSignups, addNewAllowlistSignups, toggleAddAllowistModal, updateAllowListedSignups, toggleEditAllowistModal,
  deleteAllowListedSignups, toggleDeleteAllowistModal
};

const mapStateToProps = ({ Auth }) => {
  let { isAddAllowlistModal, isEditAllowlistModal, isDeleteAllowlistModal, allAddressesSignups, CrowdSaleABI, CrowdSaleAddress } = Auth;
  return { isAddAllowlistModal, isEditAllowlistModal, isDeleteAllowlistModal, allAddressesSignups, CrowdSaleABI, CrowdSaleAddress };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllowlistSignups);