import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Button from '@material-ui/core/Button';
import React, { Component, Fragment } from 'react';
import { validate } from 'wallet-address-validator';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import { web3 } from '../../store/web3';
import { explorer } from '../../store/config';
import { setLoader, getAllowListed, updateAllowListed } from '../../store/actions/Auth'


class Allowlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: [],
      address: '',
      addressArray: [],
      allowlistData: [],
      isAllowlistModal: false,
    };
  };

  async componentDidMount() {
    this.props.getAllowListed();
  };

  toggleAllowistModal = () => this.setState({ isAllowlistModal: !this.state.isAllowlistModal }, () => {
    let { isAllowlistModal } = this.state;
    if (!isAllowlistModal) this.setState({ address: '' })
  });

  handleEditChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if ([e.target.name] == 'address') {
      let { addressArray } = this.state;
      let address = e.target.value;
      addressArray = address.split(',');
      this.setState({ addressArray })
    }
  };

  addAllowlist = async () => {
    try {
      let { addressArray } = this.state;
      let { CrowdSaleAddress, CrowdSaleABI } = this.props;

      let crowdSale = new web3.eth.Contract(CrowdSaleABI, CrowdSaleAddress);

      if (addressArray.length == 0) {
        EventBus.publish('error', `Please enter the addresses`);
        return;
      };

      for (let i = 0; i < addressArray.length; i++) {
        if (!validate(addressArray[i], 'ETH')) {
          EventBus.publish('error', `No. ${i + 1} is not a valid address`);
          return;
        };
        let isAllowlisted = await (crowdSale.methods.isAllowlistSaleAllowed(addressArray[i]).call());
        if (isAllowlisted) {
          EventBus.publish('error', `No. ${i + 1} Address already allowlisted`);
          return;
        }
      };

      /** Add Allowlist **/
      const from = (await web3.currentProvider.enable())[0];
      this.props.setLoader({ message: 'Allowlisting in Progress...', status: true });
      await web3.eth.sendTransaction({
        from,
        value: 0,
        to: CrowdSaleAddress,
        data: crowdSale.methods.addToAllowlistSale(addressArray).encodeABI(),
      }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async (receipt) => {
          this.props.updateAllowListed({ addresses: addressArray, txHash: receipt['transactionHash'] });
          this.toggleAllowistModal();

          // let allowlistData = await ICO.getPastEvents('AddedToAllowlistSale', { fromBlock: 0, toBlock: 'latest' });
          // for (let x = 0; x < allowlistData.length; x++) {
          //   let time = await web3.eth.getBlock(allowlistData[x]['blockNumber']);
          //   allowlistData[x]['timestamp'] = time['timestamp'];
          // }

          this.setState({ address: '', addressArray: [] });
          this.props.setLoader({ status: false });
          EventBus.publish('success', `Allowlisted Successfully`);
        })

    } catch (e) {
      this.toggleAllowistModal();
      EventBus.publish('error', `Unable to Allowlist Address`);
      this.props.setLoader({ message: 'Allowlisting not Completed...', status: false });
    };
  };

  render() {
    let { allAddresses } = this.props;
    let { address, isAllowlistModal } = this.state;

    const columns = [
      {
        id: 'returnValues',
        Header: 'Allowlisted Address',
        accessor: (allAddresses) => allAddresses['publicAddress'] ? allAddresses['publicAddress'] : '-',
      },
      {
        id: 'blockNumber',
        Header: 'Date',
        accessor: (allAddresses) => allAddresses['createdAt']
      },
      {
        id: 'transactionHash',
        Header: 'Transaction Hash',
        accessor: (allAddresses) =>
          allAddresses['txHash'] ? (
            <a href={`${explorer}/tx/${allAddresses['txHash']}`} target='_blank' style={{ color: '#0c0f0b' }}>
              View on Explorer
            </a>
          ) : ('-'),
        filterable: false,
      },
    ];

    return (
      <div className='content'>
        <div className="main-container mintedNFT">
          <div className='main-container-head mb-3'>
            <p className="main-container-heading">Allow List</p>
            <button onClick={this.toggleAllowistModal} className="add-btn">Add Allowlist</button>
          </div>
          <Fragment>
            <div className='main-container-head mb-3'>
              <ReactTable
                minRows={20}
                className="table"
                columns={columns}
                filterable={true}
                data={allAddresses}
                resolveData={allAddresses => allAddresses.map(row => row)}
              />
            </div>
          </Fragment>
        </div>

        {/* ---------------ADD ALLOWLIST MODAL--------------- */}

        <Modal isOpen={isAllowlistModal} toggle={this.toggleAllowistModal} className="main-modal allowlist-modal">
          <ModalHeader toggle={this.toggleAllowistModal}>
            <div className="modal-logo">
              <img src={require('../../assets/img/logo.png')} alt="modal-logo" />
            </div>
            <div className="text-center"><h2 className="text-white">ADD ALLOWLIST</h2></div>
            <div className="text-white"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.addAllowlist}>
              <div className='row'>
                {/* <div className="col-2"></div> */}
                <div className='col-12'>
                  <TextValidator
                    fullWidth
                    multiline
                    rows={3}
                    type='text'
                    name='address'
                    value={address}
                    placeholder="Enter the Addresses"
                    className='text-field'
                    id='standard-full-width'
                    onChange={this.handleEditChange}
                    helperText="Please seperate Address with comma(,) and don't put space in between."
                  />
                </div>
                {/* <div className="col-2"></div> */}
              </div>
              <div className="row">
                <div className="col-12 pt-4 pb-5 d-flex justify-content-center">
                  <Button type="submit" variant="contained" className="add-btn">
                    Submit
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader, getAllowListed, updateAllowListed,
};

const mapStateToProps = ({ Auth }) => {
  let { allAddresses, CrowdSaleABI, CrowdSaleAddress } = Auth;
  return { allAddresses, CrowdSaleABI, CrowdSaleAddress };
};
export default connect(mapStateToProps, mapDispatchToProps)(Allowlist);