const { web3 } = require('../web3');
const { env } = require('../config');

const TokenData = require(`./${env}/Token.json`);
const ERC1155TokenData = require(`./${env}/ERC1155Token.json`);
const ICOData = require(`./${env}/ICO.json`);
const RoyalityData = require(`./${env}/Royality.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const TokenABI = TokenData['abi'];
const TokenAddress = TokenData['address'];
const Token = new web3.eth.Contract(TokenABI, TokenAddress);


const ERC1155TokenABI = ERC1155TokenData['abi'];
const ERC1155TokenAddress = ERC1155TokenData['address'];
const ERC1155Token = new web3.eth.Contract(ERC1155TokenABI, ERC1155TokenAddress);

const ICOABI = ICOData['abi'];
const ICOAddress = ICOData['address'];
const ICO = new web3.eth.Contract(ICOABI, ICOAddress);

const RoyalityABI = RoyalityData['abi'];
const RoyalityAddress = RoyalityData['address'];
const Royality = new web3.eth.Contract(RoyalityABI, RoyalityAddress);

module.exports = {
  Token, TokenABI, TokenAddress,
  ERC1155Token, ERC1155TokenABI, ERC1155TokenAddress,
  ICO, ICOABI, ICOAddress,
  Royality, RoyalityABI, RoyalityAddress,
};
