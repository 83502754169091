import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component } from 'react'
import { Button } from '@material-ui/core';
import { validate } from 'wallet-address-validator';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import './index.css';
import { web3 } from '../../store/web3';
import { setLoader } from '../../store/actions/Auth.js';
import tradingCards from '../../store/tradingCards.json';


class Airdrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenIds: [],
      quantity: [],
      address: [],

    };
  };

  submitAirdrop = async () => {
    let { TokenABI, TokenAddress } = this.props;
    let Token = new web3.eth.Contract(TokenABI, TokenAddress);

    try {
      let { tokenIds, quantity, address } = this.state;
      if (tokenIds.length == 0) {
        EventBus.publish('error', `Please select the Token IDs`);
        return;
      };

      if (quantity.length == 0) {
        EventBus.publish('error', `Please enter the quantity of tokens`);
        return;
      };

      if (quantity.length != tokenIds.length) {
        EventBus.publish('error', `Token Id's are not matched with quantities`);
        return;
      }

      if (address.length == 0) {
        EventBus.publish('error', `Please enter the address`);
        return;
      };

      for (let i = 0; i < address.length; i++) {
        if (!validate(address[i], 'ETH')) {
          EventBus.publish('error', `This ${address[i]} is not valid`);
          return;
        };
      }

      const from = (await web3.currentProvider.enable())[0];
      /** Mint New NFT **/
      this.props.setLoader({ message: 'Airdrop in Progress...', status: true });
      await web3.eth.sendTransaction({
        from,
        value: 0,
        to: TokenAddress,
        data: Token.methods.airdrop(address, tokenIds, quantity).encodeABI(),
      }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async (receipt) => {
          this.setState({ address: [], tokenIds: [], quantity: [] });
          this.props.setLoader({ status: false });
          EventBus.publish('success', `NFT Airdrop successfully`);
        })
    } catch (e) {
      console.log('********Error = ', e);
      this.props.setLoader({ message: 'Airdrop not Completed...', status: false });
      EventBus.publish('error', `Unable to Airdrop NFT`);
    };
  };

  render() {
    let { tokenIds, quantity, address } = this.state;

    return (
      <div className='content'>
        <div className="main-container mint-new">
          <div className='mint-new-NFT'>
            <div className='edit-add row'>
              <div className="edit-add-title col-12">
                <h1 className="text-dark">Airdrop NFT's</h1>
              </div>
              <div className="edit-add-body col-12">
                <ValidatorForm onSubmit={this.submitAirdrop}>
                  <div className="row">
                    <div className='edit-add-field text-left offset-md-2 col-md-8 col-sm-12 mt-4'>
                      <Select
                        multiple
                        value={tokenIds}
                        variant="outlined"
                        id="demo-multiple-checkbox"
                        onChange={(e) => this.setState({ tokenIds: e.target.value })}
                        className='form-input-field w-100'
                        renderValue={(selected) => selected.join(', ')}
                      >
                        <MenuItem disabled value="">
                          <em>Select Token IDs</em>
                        </MenuItem>
                        {tradingCards.map(item => (
                          <MenuItem key={item['tokenId']} class="d-flex" value={item['tokenId']}>
                            <Checkbox checked={tokenIds.indexOf(item['tokenId']) > -1} />
                            <ListItemText primary={item['name']} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className='edit-add-field offset-md-2 col-md-8 col-sm-12 mt-4'>
                      <TextValidator
                        fullWidth
                        type='text'
                        name='quantity'
                        value={quantity}
                        variant="outlined"
                        id='standard-full-width'
                        className='form-input-field'
                        label={<label>No. of Tokens <span>*</span></label>}
                        onChange={(e) => this.setState({ quantity: e.target.value.split(',') })}
                        helperText="Please seperate Quantity with comma(,) and don't put space in between."
                      />
                    </div>
                    <div className='edit-add-field offset-md-2 col-md-8 col-sm-12 mt-4'>
                      <TextValidator
                        fullWidth
                        type='textarea'
                        name='address'
                        value={address.join('\n')}
                        variant="outlined"
                        id='standard-full-width'
                        className='form-input-field'
                        label={<label>Addresses <span>*</span></label>}
                        onChange={(e) => {
                          const addresses = e.target.value.split('\n')
                          this.setState({ address: addresses });
                        }}
                        multiline
                        rows={3}
                        helperText="Please separate addresses by giving line space"
                      />
                    </div>
                    <div className='edit-add-buttons col-sm-12 mt-4'>
                      <Button className="submit-btn col-4" type='submit' onSubmit={this.submitAirdrop}>Submit</Button>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader
};

const mapStateToProps = ({ Auth }) => {
  let { TokenABI, TokenAddress } = Auth;
  return { TokenABI, TokenAddress };
};
export default connect(mapStateToProps, mapDispatchToProps)(Airdrop);