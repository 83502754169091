
import Airdrop from "./views/Airdrop/index.js";
import Allowlist from "./views/Allowlist/index.js";
import AllowlistSignups from "./views/AllowlistSignups/index.js";

var routes = [
  {
    layout: "/home",
    path: "/",
    // component: Allowlist,
    component: Airdrop,
    hidden: true,
  },
  // {
  //   layout: "/home",
  //   path: "/allowlist",
  //   name: "Allow List",
  //   component: Allowlist,
  //   icon: "tim-icons icon-bullet-list-67",
  // },
  {
    layout: "/home",
    path: "/airdrop",
    name: "Airdrop",
    component: Airdrop,
    icon: "tim-icons icon-simple-add",
  },
  {
    layout: "/home",
    path: "/allowList-signup",
    name: "AllowlistSignups",
    component: AllowlistSignups,
    icon: "tim-icons icon-badge",
  },
];

export default routes;